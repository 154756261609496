<template>
  <Loading v-if="!data.mounted || !data.dataSheets" size="small" />
  <div v-else class="downloads__sheets">
    <div class="downloads__search__filter">
      <div class="downloads__search__container">
        <input
          v-model="data.searchQuery"
          placeholder="Search Data Sheet Title, Product Code"
          type="search"
        />
        <button ref="submit" type="submit">
          <icon-search />
        </button>
      </div>
      <div class="downloads__filter__container">
        <div
          :class="[
            'downloads__filter',
            { 'downloads__filter--active': data.isFilterBy },
          ]"
          @click="data.isFilterBy = !data.isFilterBy"
        >
          <p class="small cursor-pointer">
            Sort by: {{ data.sortByFilter.name }}
          </p>
        </div>
        <ul>
          <transition-expand>
            <ul v-if="data.isFilterBy">
              <li
                @click="updateSortByFilter(filter)"
                v-for="filter in sortByFilters"
                :key="filter.id"
              >
                <p
                  v-if="data.sortByFilter.id !== filter.id"
                  class="small cursor-pointer"
                >
                  {{ filter.name }}
                </p>
              </li>
            </ul>
          </transition-expand>
        </ul>
      </div>
    </div>
    <div class="downloads__lists">
      <template v-if="data.dataSheets.length">
        <hr />
        <div
          v-for="(sheet, idx) in data.searchQuery !== ''
            ? queriedDataSheets
            : paginatedDataSheets"
          :key="idx"
          class="downloads__sheet"
        >
          <div>
            <p class="accent" v-if="sheet.title" v-html="sheet.title"></p>
            <div
              v-if="
                (sheet.product_one_code && sheet.product_one_link) ||
                (sheet.product_two_code && sheet.product_two_link) ||
                (sheet.product_three_code && sheet.product_three_link)
              "
              class="downloads__links"
            >
              <p class="downloads__prefix">Product Codes:</p>
              <a
                v-if="sheet.product_one_code && sheet.product_one_link"
                :href="`/products/${sheet.product_one_link}`"
                class="underline cursor-pointer"
              >
                <p v-html="sheet.product_one_code"></p
              ></a>
              <a
                v-if="sheet.product_two_code && sheet.product_two_link"
                :href="`/products/${sheet.product_two_link}`"
                class="underline cursor-pointer"
              >
                <p v-html="sheet.product_two_code"></p
              ></a>
              <a
                v-if="sheet.product_three_code && sheet.product_three_link"
                :href="`/products/${sheet.product_three_link}`"
                class="underline cursor-pointer"
              >
                <p v-html="sheet.product_three_code"></p
              ></a>
            </div>
            <p class="light" v-if="sheet.version">
              Version: {{ sheet.version }}
            </p>
            <p class="light" v-if="sheet.date_issued">
              Date issued:
              {{ new Date(sheet.date_issued).toLocaleDateString('en-nz') }}
            </p>
          </div>
          <div class="downloads__action">
            <a :href="sheet.data_sheet" target="_blank" download>
              <button
                class="btn__base btn__base--light"
              >
                <icon-download />Download PDF
              </button>
            </a>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="accent">There are no Data Sheets.</p>
      </template>
    </div>
    <nav
      aria-label="Pagination"
      class="pagination__wrap"
      role="navigation"
      v-if="numberOfPages > 1 && data.searchQuery === ''"
    >
      <paginate
        :v-model="data.currentPage"
        :page-count="numberOfPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="handlePaginationChange"
        :prev-text="''"
        :next-text="''"
        :container-class="'pagination'"
        :page-class="'page'"
      >
      </paginate>
    </nav>
  </div>
</template>

<script setup>
import { reactive, onMounted, onBeforeMount, computed } from 'vue';
import { windowOpen } from '../../utils/helpers';
import Paginate from 'vuejs-paginate-next';
import Loading from './Loading.vue';
import IconSearch from './icons/IconSearch.vue';
import IconDownload from './icons/IconDownload.vue';
import TransitionExpand from './transitions/TransitionExpand.vue';

const props = defineProps({
  data_sheets: {
    type: Array,
    required: true,
  },
});

const data = reactive({
  searchQuery: '',
  isFilterBy: false,
  sortByFilter: {
    id: 1,
    name: 'Title',
  },
  mounted: false,
  dataSheets: null,
  currentPage: 1,
  dataSheetsPerPage: 7,
});

const sortByFilters = [
  {
    id: 1,
    name: 'Title',
  },
  {
    id: 2,
    name: 'Newest to oldeset',
  },

  {
    id: 3,
    name: 'Version',
  },
];

const updateSortByFilter = (filter) => {
  data.sortByFilter = filter;
  //sort by Title
  if (filter.id === 1) {
    data.dataSheets.sort((a, b) =>
      a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
    );
  }
  //sort by Newest to oldest
  if (filter.id === 2) {
    data.dataSheets.sort((a, b) =>
      new Date(a.date_issued) < new Date(b.date_issued) ? 1 : -1
    );
  }
  //sort by Version
  if (filter.id === 3) {
    data.dataSheets.sort((a, b) => (b.version > a.version ? 1 : -1));
  }
};

const paginatedDataSheets = computed(() =>
  data.dataSheets.slice(
    (data.currentPage - 1) * data.dataSheetsPerPage,
    data.currentPage * data.dataSheetsPerPage
  )
);

const queriedDataSheets = computed(() => {
  return data.dataSheets.filter((sheet) => {
    return (
      sheet.title.toLowerCase().indexOf(data.searchQuery.toLowerCase()) != -1 ||
      sheet.product_one_code
        .toLowerCase()
        .indexOf(data.searchQuery.toLowerCase()) != -1 ||
      sheet.product_two_code
        .toLowerCase()
        .indexOf(data.searchQuery.toLowerCase()) != -1 ||
      sheet.product_three_code
        .toLowerCase()
        .indexOf(data.searchQuery.toLowerCase()) != -1
    );
  });
});

const setCurrentPage = (number) => {
  data.currentPage = number;
};

const numberOfPages = computed(() => {
  return Math.ceil(data.dataSheets.length / data.dataSheetsPerPage);
});

const handlePaginationChange = (pageNum) => {
  setCurrentPage(pageNum);
};

onBeforeMount(() => {
  data.dataSheets = props.data_sheets;
});

onMounted(() => {
  data.mounted = true;
});
</script>

<style scoped>
.pagination__wrap {
  margin-top: 35px;
  justify-content: flex-start;
}
</style>
