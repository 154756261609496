<template>
  <div>
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.07726 7.29317C8.14208 7.23192 8.19551 7.13134 8.2369 6.99127C8.27828 6.8512 8.29864 6.66008 8.29864 6.41749C8.29864 6.17502 8.27828 5.98934 8.2369 5.85931C8.19605 5.72998 8.13846 5.6287 8.06413 5.55685C7.99034 5.48375 7.89672 5.43492 7.78328 5.40939C7.69864 5.38944 7.53243 5.37939 7.28466 5.37939H7.01172V7.45375H7.46467C7.63449 7.45375 7.75623 7.44315 7.8319 7.42306C7.93087 7.39753 8.01244 7.35386 8.07726 7.29318V7.29317Z"
        fill="#303030"
      />
      <path
        d="M12.0226 0H0V20H15V3.10143L12.0226 0ZM9.44383 4.84929H11.5054V5.37929H10.0509V6.12118H11.3061V6.65118H10.0509V7.98182H9.44383V4.84929ZM6.40485 4.84929H7.5143C7.76448 4.84929 7.95531 4.86924 8.0867 4.90928C8.26308 4.96368 8.41429 5.05925 8.5403 5.19806C8.66565 5.33617 8.76168 5.50498 8.82771 5.70559C8.89373 5.90621 8.92614 6.15245 8.92614 6.44624C8.92614 6.70377 8.89494 6.92627 8.83373 7.11252C8.75873 7.34006 8.65132 7.52434 8.51217 7.66566C8.40664 7.77253 8.2644 7.85623 8.08562 7.91566C7.95183 7.95947 7.77237 7.98123 7.54802 7.98123H6.40562L6.40536 4.84927L6.40485 4.84929ZM3.60521 4.84929H4.57968C4.94866 4.84929 5.18931 4.86491 5.30143 4.89616C5.47366 4.94304 5.61764 5.04488 5.73401 5.20238C5.85039 5.35989 5.90866 5.56302 5.90866 5.81302C5.90866 6.00485 5.87504 6.16612 5.80848 6.29738C5.74191 6.42866 5.65673 6.5312 5.55348 6.60611C5.45022 6.68047 5.34522 6.72986 5.23848 6.75427C5.0933 6.78427 4.88382 6.79864 4.60903 6.79864H4.21299V7.98056H3.60521L3.60521 4.84929ZM11.9956 15.6507H3.00384V15.0257H11.9953L11.9956 15.6507ZM11.9956 13.4375H3.00384V12.8125H11.9953L11.9956 13.4375Z"
        fill="#303030"
      />
      <path
        d="M5.02321 6.21819C5.10357 6.18568 5.16718 6.13448 5.21285 6.06445C5.25905 5.99512 5.28182 5.91378 5.28182 5.82129C5.28182 5.70689 5.24941 5.61314 5.18526 5.53948C5.12111 5.46512 5.03941 5.4188 4.94111 5.40011C4.86852 5.3863 4.72334 5.37891 4.50438 5.37891H4.21094V6.26773H4.54335C4.78268 6.26745 4.94219 6.25127 5.02321 6.2182L5.02321 6.21819Z"
        fill="#303030"
      />
    </svg>
  </div>
</template>
